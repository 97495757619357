.btn {
  display: inline-block;
  border: 1px solid transparent;
  transition: .3s ease-in-out;
  text-decoration: none;
  padding: 11px 21px;
  min-width: 156px;
  text-align: center;
  //max-width: 220px;
  cursor: pointer;

  @media (min-width:768px) and (max-width:1240px) {
    width: 180px;
    padding: 4px 9px;
  }

  @media (min-width:320px) and (max-width:767px) {
    padding: 14px 27px;
  }

  @media screen and (max-width: 340px) {
    max-width: 300px;
  }

  &_text {
    font-size: 18px;
    line-height: 24px;

    @media (min-width:768px) and (max-width:1240px) {
      font-size: 13px;
      line-height: 30px;
    }

    @media (min-width:320px) and (max-width:767px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  span {
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  &-main {
    background-color: var(--button-color);
    border-color: var(--button-color);

    span {
      color: var(--white);
    }
    &:hover {
      opacity: 0.7;
    }
  }

  &-second {
    background-color: #1A428A;
    border-color: #1A428A;
    margin-left: 10px;
    &:first-child{
      margin-left: 0;
      @media (min-width:320px) and (max-width:767px) {
        margin-left: 0;
        min-width: 150px;
        width: 150px;
      }
    }

    @media (min-width:320px) and (max-width:767px) {
      padding: 11px 0px;
      width: 188px;
      margin-left: 0;
    }
     @media (min-width:768px) and (max-width:1023px) {
      margin-left: 0;
      width: 200px;
      padding: 10px 0px;
     }
     @media (min-width:1024px) and (max-width:1240px) {
      width: 160px;
      padding: 8px 0px;
     }

    &:hover {
      opacity: 0.8;
    }

    span {
      color: var(--white);

      @media (min-width:768px) and (max-width:1240px) {
        font-size: 13px;
        line-height: 29px;
      }
    }
  }

  &-full {
    width: 280px;
    @media (min-width:768px) and (max-width:1240px) {
      width: 200px;
    }
    @media (min-width:320px) and (max-width:767px) {
      width: 250px;
      margin: 0 auto;
    }
  }

  &-small{
    width: 50px;
    height: 20px;
    padding: 14px 0px;
    min-width: 50px;
    @media (min-width:768px) and (max-width:1023px) {
      width: 50px;
      padding: 15px 0px;
    }
    @media (min-width:320px) and (max-width:767px) {
      width: 50px;
      min-width: 50px;
      padding: 14px 0;
    }
  }

  &-more {
    min-width: 50px;
    max-width: 50px;
    height: 32px;
    background: white;
    border: none;
    color: var(--blue-color);
    padding: 0px 2px;
    display: inline-block;
    border-radius: 18px;
    text-align: center;
    border-radius: 100px;

    @media (min-width:768px) and (max-width:1240px) {
      min-width: 40px;
      max-width: 40px;
      height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      color: var(--blue-color);
      font-size: 18px;

      @media (min-width:768px) and (max-width:1240px) {
        font-size: 13px;
        line-height: 23px;
        display: block;
      }
    }
  }

  &-img {
    display: flex;
    height: 100%;

    // @media (min-width:768px) and (max-width:1240px) {
    //   height: 14px;
    // }

    // @media (min-width:320px) and (max-width:767px) {
    //   width: 20px;
    // }
  }

}