.navigation{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 74px;
    @media (min-width:768px) and (max-width:1023px){
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        }
        @media (min-width:320px) and (max-width:767px) {
            display: none;
        }
    &__header{
        display: flex;
        @media (min-width:320px) and (max-width:767px) {
            display: none;
        }
        &-mobile{
            display: none;
            @media (min-width:320px) and (max-width:1240px){
                display:flex;
                flex-direction: column;
                margin-right: 0;
            }
        }
    }
    &__link{
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-decoration: none;
        margin-right: 24px;
        color: var(--header-color);
        &:hover{
            color: var(--white);
        }
        @media (min-width:768px) and (max-width:1240px) {
            font-size: 12px;
            line-height: 38px;
            margin-right: 13px;
        }
        &-mobile{
            font-size: 18px;
            line-height: 24px;
            text-decoration: none;
            color: var(--header-color);
            margin-bottom: 22px;
            @media (min-width:320px) and (max-width:350px) {
                font-size: 15px;
                line-height: 13px;
                margin-bottom: 20px;
            }
        }
        &:last-child{
            margin-right: 0px;
        }
    }
}
.selected{
    color: var(--blue-color);
}