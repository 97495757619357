.footer{
    background: #1F1F1F;

    @media (min-width:1024px) and (max-width:1240px){
        margin-top: 70px;
        }
        @media (min-width:768px) and (max-width:1023px){
            margin-top: 50px;
            }
            @media (min-width:320px) and (max-width:767px){
                flex-direction: column;
                align-items: flex-start;
            }
    &__nav{
        padding: 0 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 330px;
        height: 100%;
        justify-content: flex-start;
        font-weight: 400;
font-size: 13px;
line-height: 25px;
        @media (min-width:1024px) and (max-width:1240px){
            padding: 0 140px 0px 0;
            width: 240px;
        }
        @media (min-width:768px) and (max-width:1023px){
            padding: 0 80px 0px 0;
            width: 240px;
        }
        @media (min-width:320px) and (max-width:767px){
            flex-direction: column;
            padding: 0 20px 0 0;
                width: auto;
        }
        & p{
            font-size: 13px;
            line-height: 25px;
            color:var(--white);
            margin-bottom: 0;
        }
        &-second{
            padding: 0 60px;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            justify-content: flex-start;
            font-weight: 400;
            @media (min-width:320px) and (max-width:767px){
                flex-direction: column;
                padding: 0 20px 0 0;
            }
            @media (min-width:768px) and (max-width:1240px){
                padding: 0 0px;
                }
            font-size: 13px;
            line-height: 25px;
            & p{

                font-size: 13px;
                line-height: 25px;
                color:var(--white);
                margin-bottom: 0;
                @media (min-width:768px) and (max-width:1240px){
                    margin-top: 0;
                }
            }
        }

        &-link{
            font-size: 12px;
            line-height: 14px;
            color: var(--header-color);
            margin-left: 15px;
            &:first-child{
                @media (min-width:768px) and (max-width:1023px){
                    margin-left: 0;
                }
            }
            @media (min-width:320px) and (max-width:767px){
                margin-left: 0;
                }
        }
    }
    &__block{
        display: flex;
        @media (min-width:320px) and (max-width:1240px){
            flex-direction: column;
        }

        &-title{
            color: var(--white);
            font-weight: 600;
            font-size: 17px;
            line-height: 21px;
            margin-right: 15px;
            @media (min-width:768px) and (max-width:1240px){
                font-size: 17px;
                line-height: 21px;
            }
                    & + p {
            margin-top: 0;
        }
        }
        &:first-child{
            width: 100%;
            @media (min-width:768px) and (max-width:1240px){
                margin-bottom: 18px;
                }
        }
        &:last-child div{
            @media (min-width:1241px) and (max-width:1388px){
                margin-right: 10px;
            }
        }

    }
    &-links{
        display: inline-block;
        width: 100%;
        text-align: left;
        color: #1A428A;
        &:hover{color: #FFFFFF;}
        transition: .3s;
    }
    &__group{
        display: flex;
        @media (min-width:320px) and (max-width:1240px){
            flex-direction: column;
        }
    }
    &__main{
        margin-top: 100px;
        padding: 60px 0;
        align-items: center;
            display: flex;
            @media (min-width:320px) and (max-width:1240px){
                margin-top: 0px;
                padding: 50px 0;

            }
            @media (min-width:320px) and (max-width:767px){
                flex-direction: column;
            }
    }

}
.bot_footer{
    //margin-left: 60px;

    width: 120px;
    border-radius: 50%;
     @media (min-width:320px) and (max-width:1240px){
        //margin-left: 20px;
     }
     & img{
         max-width: 100%;
         object-fit: contain;
         height: inherit;
         display: block;
     }
}
