.first {
    width: 100%;
    position: relative;
    background: url('../../img/mask.png'),url('../../img/shame2.png');
            background-position: calc(50vw - 730px) center, center center;
        background-repeat: no-repeat;
        background-size: 940px, 1440px;
        margin-top: 73px;
        @media (min-width:768px) and (max-width:1240px) {
            margin-top: 60px;
        }
        @media (min-width:1024px) and (max-width:1366px) {
            background-size: contain;
        }
        @media (min-width:768px) and (max-width:1023px) {
            width: 100%;
            position: relative;
            background-position: -483px 0px;
            background-repeat: no-repeat;
            background-size: 160%;
            height: 600px;
        }
    @media (min-width:320px) and (max-width:767px) {
        background-image:url('../../img/shame-mob2.png'), url('../../img/shame-mob.png');
        background-position: center 200px,center 130px;
        background-size: auto, auto;
        height: 830px;
        margin-top: 0px;
        position: relative;
    }

    @media (min-width:320px) and (max-width:350px) {
        background-position: -190px 35px;
    }

    &__title{
        padding-bottom: 55px;
        @media (min-width:1024px) and (max-width:1240px) {
            padding-bottom: 50px;
        }
        @media (min-width:768px) and (max-width:1023px) {
            padding-bottom: 43px;
            padding-top: 520px;
        }
         @media (min-width:320px) and (max-width:767px) {
            padding-bottom: 0px;
            margin-top: 390px;
         }
         @media (min-width:320px) and (max-width:350px) {
            margin-top: 200px;
         }
    }

    &__header {
        width: 54%;
        padding-top: 50px;

        @media (min-width:1024px) and (max-width:1240px) {
            padding-top: 0px;
        }

        @media (min-width:768px) and (max-width:1023px) {
            padding-top: 25px;
        }

        @media (min-width:320px) and (max-width:767px) {
            padding-top: 25px;
            width: 100%;
        }

        &-title{
            width: 650px;
            @media (min-width:1024px) and (max-width:1240px) {
                width: 430px;
            }
            @media (min-width:768px) and (max-width:1023px) {
                width: 450px;
        }
            @media (min-width:320px) and (max-width:767px) {
                width: 100%;
            }
        }
        &-span{
            color: var(--button-color);
            margin-left: 5px;
        }

        &-text {
            width: 483px;
            margin-bottom: 50px;

            @media (min-width:1024px) and (max-width:1240px) {
                width: 390px;
            }

            @media (min-width:768px) and (max-width:1023px) {
                width: 75%;
                font-size: 14px;
                line-height: 20px;
            }

            @media (min-width:320px) and (max-width:767px) {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }

    &__absolute {
        position: absolute;
        background-image: url('../../img/first.png');
        width: 100%;
        right: 0;
        top: 50px;
        bottom: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        @media (min-width:1024px) and (max-width:1088px) {
            background-image: url('../../img/first.png');            
            top: -40px;
            width: 56%;
            background-size: cover;
        }
        @media (min-width:1089px) and (max-width:1240px) {
            top: -95px;
        }

        @media (min-width:768px) and (max-width:1023px) {
            background-image: url('../../img/first.png');
            width: 384px;
            background-position: center;
            background-size: 87%;
            top: 0;
        }

        @media (min-width:320px) and (max-width:767px) {
            height: 45%;
            position: absolute;
            background-image: url('../../img/firstmob.png');
            width: 100%;
            right: 15px;
            top: 55%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            max-width: calc(100% - 30px);
        }

    }
}