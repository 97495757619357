.history{
    display: flex;
    margin-top: 90px;
        @media (min-width:320px) and (max-width:767px) {
            flex-direction: column-reverse;
    position: relative;
    margin-top: 30px;
    margin-bottom: 80px;
    }
    @media (min-width:768px) and (max-width:1240px) {
        display: block;
    }
    &__content{
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-right: 120px;

        @media (min-width:1320px) and (max-width:1432px) {
            margin-right: 75px;
        }

        @media (min-width:320px) and (max-width:1240px) {
            margin-right: 0;
        }
        & h2{
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            margin-bottom: 14px;
            @media (min-width:320px) and (max-width:767px) {
                position: absolute;
                top: 0;
                font-size: 30px;
                line-height: 36px;
            }
            & span{
                display: inline-block;
                width: 100%;
            }
        }
        & p{
            width: 87%;
            @media (min-width:768px) and (max-width:1240px) {
                width: 630px;
                margin-top: 40px;
                margin-bottom: 30px;
            }
        }
    }
    &__img{
        flex: 1 0 auto;
        width: 45%;
        @media (min-width:320px) and (max-width:767px) {
            margin-top: 130px;
            width: 100%;
        }
        @media (min-width:320px) and (max-width:767px) {
            margin-top: 160px;
        }
        @media (min-width:768px) and (max-width:1240px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 15px;
            width: 100%;
        }
    }
    &__btn{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        @media (min-width:351px) and (max-width:767px) {
            justify-content: space-between;
        }
         @media (min-width:320px) and (max-width:350px) {
            justify-content: center;
         }
         @media (min-width:1024px) and (max-width:1240px) {
            justify-content: space-between;
            width: 350px;
         }
        @media (min-width:768px) and (max-width:1023px) {
            justify-content: space-between;
            width: 383px;
         }
    }

}