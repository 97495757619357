.about {
    display: block;
    margin-top: 103px;
    @media (min-width:320px) and (max-width:768px){
       margin-top: 80px;
    }

    &__part {
        display: grid;
        overflow: hidden;
        grid-template-columns: 48% calc(52% - 58px);
        grid-template-rows: 1fr 1fr 0.5fr 1fr;
        gap: 0 58px;
        grid-template-areas: 
            'h i'
            'p i'
            'id i'
            'id pd';

            @media (min-width:1024px) and (max-width:1240px){
                grid-template-columns: 42% 58%;
            }
            @media (min-width:768px) and (max-width:1023px){
                display: block;
                width: 85%;
            }
        @media (min-width:320px) and (max-width:767px){
            display: flex;
            flex-direction: column;
        }
        &-right {
            display: grid;
            grid-template-columns: 55% 45%;
            grid-template-rows: 3fr 5fr 5fr;
            grid-template-areas:
                'i h'
                'i h'
                'i p';
                & p:last-child{
                    margin-bottom: 0;
                }
                & h3{
                    align-self: end;
                    margin-bottom: 20px;
                    @media (min-width:768px) and (max-width:1240px){
                        margin-bottom: 0;
                    }
                }
                @media (min-width:1024px) and (max-width:1240px){
                    grid-template-rows: 1fr 5fr 5fr;
                }
                @media (min-width:768px) and (max-width:1023px){
                    display: flex;
                    flex-direction: column;
                    width: 85%;
                }
                @media (min-width:320px) and (max-width:767px){
                    display: flex;
                    flex-direction: column;
                }
        }
    }

    &__header {
        grid-area: h;
            align-self: center;
        @media (min-width:320px) and (max-width:768px){
            order: 0;
            font-size: 18px;
            line-height: 28px;
        }
    }

    &__text1 {
        width: 100%;
        grid-area: p;
        margin-top: -20px;
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 32px;
        color: var(--color-text);
        @media (min-width:320px) and (max-width:768px){
            order: 3;
            font-size: 14px;
            line-height: 26px;
        }
        & span.first__header-span{
            margin-left: 0;
            @media (min-width:320px) and (max-width:768px){
                margin-left: 0;
                font-size: 14px;
                line-height: 26px;
            }
        }
    }

    &__text2 {
        grid-area: pd;
        width: 90%;
        margin-top: 30px;
                @media (min-width:320px) and (max-width:768px){
            order: 4;
        }
    }

    &__cross {
        background-image: url('../../img/about.png');
        background-repeat: no-repeat;
        background-position: center;
        grid-area: i;
        @media (min-width:768px) and (max-width:1240px){
            background-size: contain;
        }
        @media (min-width:768px) and (max-width:1023px){
            background-image: url('../../img/about768.png');
            height: 420px;
            background-position: left;
            margin: 45px 0 36px 0;
        }
        @media (min-width:320px) and (max-width:768px){
            background-image: url('../../img/aboutmob.png');
            height: 345px;
            order: 1;
                margin: 15px 0 30px 0;
        }

        &-second {
            order: 3;
            background-image: url('../../img/aboutfake.png'), url('../../img/aboutparad.png');
            grid-area: id;
            background-repeat: no-repeat;
            background-position: center right, bottom left;
            margin-right: -10px;
            @media (min-width:768px) and (max-width:1240px){
                background-image: url('../../img/aboutfake2.png'), url('../../img/aboutparad2.png');
                background-position: center right, bottom left;
                background-size: 58%,82%;
            }
             @media (min-width:768px) and (max-width:1023px){
                height: 460px;
                order: 3;
             }
            @media (min-width:320px) and (max-width:768px){
                background-image: url('../../img/aboutfakemob.png');
                background-position: left;
                height: 320px;
                width: 100%;
                margin: 15px 0 30px;
            }
        }

        &-third {
            display: none;
            @media (min-width:320px) and (max-width:768px){
                display: block;
                height: 210px;
                background-image: url('../../img/aboutparadmob.png');
                background-position:center;
                order: 5;
                margin: 15px 0 20px;
            }
        }
    }
    &-wrap{
        @media (min-width:768px) and (max-width:1023px){
            padding-top: 200px;
        }
    }
    &__span{
        margin-top: 0;
        color: var(--button-color);
    }
}
