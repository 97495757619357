.ask {
    margin-top: 130px;
    margin-bottom: 80px;
    @media (min-width:351px) and (max-width:767px) {
        margin-top: 30px;
        margin-bottom: 0px;
    }
    @media (min-width:320px) and (max-width:350px) {
        margin-top: 30px;
        margin-bottom: 0px;
        }
    &__wrap{
        display: grid;
        grid-template-columns: 560px calc(100% - 560px);
        grid-template-areas:
        'p1 img'
        'ul img'
        'p2 img'
        'call img';
        @media (min-width:320px) and (max-width:767px) {
            display: flex;
            flex-direction: column-reverse;
                 margin: 0px auto;     
                 position: relative;
                 display: flex;
                flex-direction: column;
        }
        & ul {
            grid-area: ul;
            @media (min-width:320px) and (max-width:767px) {
                order: 3;
            }
        }
        &-p{
            grid-area: p2;
            @media (min-width:320px) and (max-width:767px) {
                order: 4;
            }
        }
    }

    &__header{
        //margin-left: 75px;
        @media (min-width:320px) and (max-width:767px) {
            margin-left: 20px;
        }
    }

    &__content{
        width: 560px;
        margin-top: 14px;
        grid-area: p1;
         @media (min-width:768px) and (max-width:1240px) {

            width: 460px;
         }
        @media (min-width:320px) and (max-width:767px) {
            width: 100%;
            order: 1;
        }
        &-bottom{
            grid-area: call;
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 20px;
            @media (min-width:320px) and (max-width:767px) {
                    flex-direction: column;
                    order: 5;
                    align-items: start;
            }
            & p{
                margin-top: 0;
                margin-bottom: 0;
                @media (min-width:320px) and (max-width:767px) {
                    margin-bottom: 15px;
                }
            }
        }
        &-block{
            background: rgba(255, 255, 255, 0.2);
            padding: 5px 10px;
            margin-right: 10px;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            color: var(--white);
            @media (min-width:320px) and (max-width:767px) {
                margin-right: 0;
                margin-bottom: 10px;
                margin-left: 0px;
                width: fit-content;
            }
        }
        & ul{
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
    &__image{
        grid-area: img;
        background-image: url('../../img/ask.png');
        background-position: right -15px;
        background-repeat: no-repeat;
        background-size: contain;
        @media (min-width:768px) and (max-width:1240px) {
            background-image: url('../../img/ask.png');
        }
        @media (min-width:320px) and (max-width:767px) {
            background-image: url('../../img/askmob.png');
                background-position: top;
                order: 2;
                height: 390px;
        }
        
    }
    &__btn{
        margin-top: 20px;
        @media (min-width:320px) and (max-width:767px) {
            margin-left: 20px;
        }
    }
    &-span{
        color: var(--white);
    }
}
