@import './fonts.scss';
@import './variables.scss';

#root{
  font-family: 'Inter';
  font-style: normal;
  padding: 0px;
  margin: 0px;
  display: block;
  background: var(--main-color);
  @media (min-width:1024px) and (max-width:1240px){
    // font-size: 14px;
    // line-height: 20px;
  }
}
body{
  padding: 0px;
  margin: 0px;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
}
h1{
  display: none;
  font-weight: 700;
  font-size: 120px;
  line-height: 145px;
  text-align: center;
  color: rgba(240, 37, 37, 0.6);
  @media (min-width:1024px) and (max-width:1240px){
    font-weight: 700;
    font-size: 85px;
    line-height: 103px;
  }
  @media (min-width:768px) and (max-width:1023px){
  font-size: 70px;
  line-height: 85px;
  }
  @media (min-width:320px) and (max-width:767px){
    display: block;
    color: #F02525;
    font-size: 38px;
    line-height: 34px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
}

h2{
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: var(--white);
   @media (min-width:768px) and (max-width:1240px){
    font-size: 38px;
    line-height: 46px;
   }
   @media (min-width:320px) and (max-width:767px){
    font-size: 30px;
    line-height: 36px;
     }
}

h3{
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  color: var(--white);
  @media (min-width:768px) and (max-width:1240px){
    font-size: 20px;
    line-height: 34px;
  }
  @media (min-width:320px) and (max-width:767px){
    // font-size: 24px;
    // line-height: 32px;
  }
}

img{
  width: 100%;
  height: auto;
  object-fit: contain;
}

a{
  text-decoration: none;
}

p{
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 32px;
  color: var(--color-text);
  @media (min-width:768px) and (max-width:1240px){
    font-size: 16px;
    line-height: 28px;
  }
  @media (min-width:320px) and (max-width:767px){
    font-size: 14px;
    line-height: 26px;
  }
}
li{
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 32px;
  color: var(--color-text);
  @media (min-width:768px) and (max-width:1240px){
    font-size: 16px;
    line-height: 28px;
  }
  @media (min-width:320px) and (max-width:767px){
    font-size: 14px;
    line-height: 26px;
  }
}

.container{
  max-width: 1290px;
  width:calc(100% - 150px);
  margin: 0 auto;

  @media (min-width:768px) and (max-width:1240px){
    width:calc(100% - 100px);
  }
  @media (min-width:320px) and (max-width:767px){
    max-width: calc(100% - 40px);
    width: calc(100% - 40px);
  }

}

.container.ask{
  max-width: 100%;
    width: 100%;
}

.solid{
  color: var(--main-color);
}

.bold{
  font-weight: 500;
}

.loading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}


