.howwhere {
    margin-top: 40px;
    display: flex;
    column-gap: 145px;

    @media (min-width:320px) and (max-width:767px) {
        flex-direction: column;
        margin-top: 50px;
    }

    &__block{
        display: flex;
    justify-content: space-between;
    background-image: url('../../img/howhere2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    }

    &__header {
        margin-bottom: 10px;
        @media (min-width:320px) and (max-width:767px) {
            margin-left: 0px;
            margin-bottom: 0;
        }
        & p{
            @media (min-width:320px) and (max-width:767px) {
                width: 90%;
            } 
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        width: fit-content;
        justify-content: center;

        @media (min-width:320px) and (max-width:767px) {
            order: 2;
            margin-bottom: 56px;
        }

        @media (min-width:768px) and (max-width:1240px) {
            max-width:42%;
        }

        &-img {
            flex: 0 0 516px;

            @media (min-width:320px) and (max-width:767px) {
                order: 1;
                flex: 1 0 auto;
            }
        }

        &:last-child {
            text-align: left;
            flex: 0 0 510px;

            @media (min-width:320px) and (max-width:767px) {
                text-align: left;
                flex: 1 0 auto;
            }
        }
    }
    &__btn{
        margin-top: 10px;
    }

}