.donate{
display: block;
margin-top: 115px;
@media (min-width:320px) and (max-width:767px) {
    margin-top: 20px;
}
&__content{
    display: flex;
    @media (min-width:320px) and (max-width:767px) {
        flex-direction: column-reverse;
    }
    &-text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (min-width:768px) and (max-width:1023px) {
            max-width: 400px;
            padding: 60px 0;
        }
    }
    &-img{
       flex: 1 0 645px;
       @media (min-width:320px) and (max-width:767px) {
        flex: 1 0 auto;
       }
    }
}

&__list{
    display: flex;
    flex-wrap: wrap;
        margin-top: -30px;
        @media (min-width:320px) and (max-width:767px) {
            margin-top: 30px;
        }
    &-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 0 0 170px;
        padding: 50px 36px 35px;
        margin-right: 20px;
        background: linear-gradient(180deg, #181818 0%, rgba(24, 24, 24, 0) 100%);
        @media (min-width:1356px) {
        &:nth-child(5n){
            margin-right: 0;
        }
    }
    @media (min-width:768px) and (max-width:1023px) {
        &:nth-child(3n){
            margin-right: 0;
        }
    }
    @media (min-width:1024px) and (max-width:1240px) {
        &:nth-child(4n){
            margin-right: 0;
        }
        }
        @media (min-width:351px) and (max-width:767px) {
            padding: 30px 10px;
            flex: 0 0 26%;
            margin-right: 3px;
            &:nth-child(3n){
                margin-right: 0;
            }
        }
        @media (min-width:320px) and (max-width:350px) {
            padding: 30px 8px;
            flex: 0 0 25%;
            margin-right: 3px;
                        &:nth-child(3n){
                margin-right: 0;
            }
            }
        @media (min-width:1241px) and (max-width:1355px) {
            flex: 0 0 190px;
            &:nth-child(4n){
                margin-right: 0;

            }
        }
        @media (min-width:768px) and (max-width:1023px) {
            padding: 40px 10px;
            width: 38%;

        }
    }
    &-img{
        width: 55px;
    }
    &-bigitem{
        text-align: center;
        padding: 60px 105px;
        height: 100%;
        width: 32%;
        flex: 1 0 auto;
        background: linear-gradient(180deg, #181818 0%, rgba(24, 24, 24, 0) 100%);
        @media (min-width:1241px) and (max-width:1355px) {
            padding: 60px 100px;
        }
        @media (min-width:1024px) and (max-width:1240px) {
            width: 36%;
        }
        @media (min-width:351px) and (max-width:767px) {
            padding: 55px 50px;
            width: 100%;
            flex: 1;
            align-items: center;
            display: flex;
            flex-direction: column;
        }
        @media (min-width:320px) and (max-width:350px) {
            padding: 52px 40px;
        }
        & div {
            width:auto;
        }
    }
    &-number{
        background:  #00924C;
        color: var(--white);
        padding: 2px 7px;
        margin: 25px 0 14px;
    }
    &-text{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--color-text);
        @media (min-width: 320px) and (max-width: 360px){
            max-width: 87px;
        }
        @media (min-width:320px) and (max-width:767px) {
            font-size: 12px;
            line-height: 16px;
            font-weight: normal;
        }

    }
    &-title{
        font-weight: 700;
        font-size: 38px;
        line-height: 46px;
        margin-bottom: 20px;
        @media (min-width:320px) and (max-width:767px) {
            font-weight: 700;
            font-size: 30px;
            line-height: 36px;
            text-align: center;
            }
    }
}
&__background{
    background-image: url('../../img/donateimg.png');
        background-repeat: no-repeat;
    background-position: right;
}
}