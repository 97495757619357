.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: var(--header-color);
  z-index: 1;

  @media (min-width:320px) and (max-width:767px) {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    width: 90px;
    margin-top: 20px;
    margin-right: 10px;
  }

  &-transparent {
    background: var(--main-color);

    // @media (min-width:320px) and (max-width:767px) {
    //   background: linear-gradient(120.94deg, #FCF1FF 25.18%, #C4D3FF 185.58%);
    // }
  }

  &-white {
    background: var(--white);
    border-bottom: 1px solid #DADCE5;

    @media (min-width:320px) and (max-width:767px) {
      background: linear-gradient(120.94deg, #FCF1FF 25.18%, #C4D3FF 185.58%);
    }
  }

  &-mob {
    background: var(--white);
    position: fixed;
    height: 100%;

    @media (min-width:320px) and (max-width:1240px) {
      background-color: var(--main-color);
    }
  }

  &-container {
    display: flex;
    max-width: 1290px;
    width: calc(100% - 150px);
    justify-content: space-between;
    margin: 0 auto;
    align-items: center;

    @media (min-width:768px) and (max-width:1240px) {
      max-width: calc(100% - 80px);
    }

    @media (min-width:320px) and (max-width:767px) {
      max-width: calc(100% - 40px);
      margin: 0 auto;
      width: auto;
      z-index: 2;
    }
  }

  &__visible {
    display: none;

    // @media (min-width:320px) and (max-width:768px) {
    //   display: block;
    // }
  }

  &__logo {
    height: 60px;
    width: 60px;

    &-container {
      display: none;

      @media (min-width:320px) and (max-width:1240px) {
        margin: 20px 0 10px 40px;
      }

      @media (min-width:320px) and (max-width:767px) {
        margin: 0px;
        display: block;
        font-weight: 700;
        font-size: 38px;
        line-height: 34px;
        color: var(--button-color);
      }
    }

    &-name {
      font-size: 20px;
      line-height: 38px;
      color: var(--blue-color);
      margin-left: 10px;
      font-weight: 500;
    }
  }

  &__lang {
    display: flex;
    width: 60px;

    &-item {
      margin-left: 3px;
      margin-right: 3px;
      display: inline-block;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      padding: 0;
      border: none;
      color: inherit;
      background-color: transparent;

      @media (min-width:320px) and (max-width:1240px) {
        font-size: 16px;
        line-height: 19px;
        padding: 0;
      }

      &-active {
        margin-right: 3px;
        margin-left: 3px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        background: transparent;
        color: var(--white);
        border: none;
        padding: 0;

        @media (min-width:320px) and (max-width:1240px) {
          font-size: 16px;
          line-height: 19px;
          padding: 0;
        }

        &:hover {
          box-shadow: 0 8px 20px rgba(255, 255, 255, 0.3);
        }
      }

      &:hover {
        box-shadow: 0 8px 20px rgba(255, 255, 255, 0.3);
      }
    }

    // @media (min-width:320px) and (max-width:767px) {
    //   display: none;
    // }

    &-mobile {
      display: block;
      padding-top: 40px;
      width: 100%;
      text-align: center;
      background-color: var(--main-color);

      @media (min-width:320px) and (max-width:340px) {
        padding-top: 20px;
      }
    }
  }

  &__mobile {
    height: 100%;
    width: 100%;
    background-color: var(--main-color);
    z-index: 1;
    position: absolute;

    &-btn {
      display: block;
    }

    &-hidden {
      display: none;
    }
  }
}

#menu__toggle {
  opacity: 0;
}

.hamburger-menu {
  position: fixed;
  right: 50px;
  top: 20px;
  z-index: 10;
}

.menu__btn {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  background-color: transparent;
  padding: 17px;
}

.menu__btn>span,
.menu__btn>span::before,
.menu__btn>span::after {
  display: block;
  position: absolute;
  width: 26px;
  height: 3px;
  background-color: var(--header-color);
  border-radius: 2px;
}

.menu__btn>span::before {
  content: '';
  top: -8px;
}

.menu__btn>span::after {
  content: '';
  top: 8px;
}


#menu__toggle:checked~.menu__btn>span {
  transform: rotate(45deg);
}

#menu__toggle:checked~.menu__btn>span::before {
  top: 0;
  transform: rotate(0);
}

#menu__toggle:checked~.menu__btn>span::after {
  top: 0;
  transform: rotate(90deg);
}

#menu__toggle:checked~.menu__box {
  visibility: visible;
  left: 0;
}

.menu__btn>span,
.menu__btn>span::before,
.menu__btn>span::after {
  transition-duration: .25s;
}

.menu__box {
  transition-duration: .25s;
}

.menu__item {
  transition-duration: .25s;
}

#menuApp__toggle {
  opacity: 0;
}

.hamburgerApp-menu {
  position: fixed;
  top: 20px;
  z-index: 10;


  @media (min-width:320px) and (max-width:1240px) {
    right: 70px;
  }
}

.menuApp__btn {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  background-color: #386CC5;
  padding: 17px;
}

.menuApp__btn>span,
.menuApp__btn>span::before,
.menuApp__btn>span::after {
  display: block;
  position: absolute;
  width: 26px;
  height: 3px;
  background-color: white;
  border-radius: 2px;
}

.menuApp__btn>span::before {
  content: '';
  top: -8px;
}

.menuApp__btn>span::after {
  content: '';
  top: 8px;
}

#menu__toggle:checked~.menuApp__btn>span {
  background-color: transparent;
  transform: rotate(45deg);
}

#menu__toggle:checked~.menuApp__btn>span::before {
  background-color: #386CC5;
  top: 0;
  transform: rotate(0);
}

#menu__toggle:checked~.menuApp__btn>span::after {
  background-color: #386CC5;
  top: 0;
  transform: rotate(90deg);
}

#menu__toggle:checked~.menuApp__btn {
  background-color: transparent;
  visibility: visible;
  left: 0;
}

.invisible {
  display: none;
}